import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../../hooks";
import FullScreenLoading from "../../common/loading/FullScreenLoading";

export default function SSOLogin() {
  const { reauthenticateActiveUser } = useAuth();
  const navigate = useNavigate();
  useEffect(async () => {
    try {
      await reauthenticateActiveUser();
    } catch (error) {
      console.log(error);
      throw error;
    }
    navigate("/dashboard");
  }, []);
  return (
    <FullScreenLoading
      loadingTrigger={true}
      loadingText="Signing you in..."
    />
  );
}
