import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../../hooks";
import FullScreenLoading from "../../common/loading/FullScreenLoading";

export default function SSOLogout() {
  const { handleLogout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    handleLogout();
    navigate("/auth/login");
  }, []);
  return (
    <FullScreenLoading
      loadingTrigger={true}
      loadingText="Logging you out..."
    />
  );
}
