import ScribblesQuestion from "../../../assets/imgs/scribbles-question.png";
export default function SSOError() {
  const params = new URLSearchParams(window.location.search);
  const message =
    params.get("message") || "Something went wrong with your SSO";

  return (
    <>
      <div className="flex flex-grow items-center justify-center flex-col gap-4 ">
        <img className="max-h-[300px]" src={ScribblesQuestion}></img>
        <div className="text-xl text-red-400">{message}</div>
        <a href="/auth/login">Back to Login</a>
      </div>
    </>
  );
}
